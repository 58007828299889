// custom change - new file
import { html } from 'lit';

export default (el, oob_charges_detailed) => {
    const oob_charges_order = ['Factura anterioara','Sold precedent', 'delimiter', 'Total platit din sold precedent', 'Corectii', 'Returnare depozit', 'Rest plata', 'delimiter','Factura curenta', 'Abonamente si extraoptiuni', 'Servicii utilizate', 'Chirii echipamente', 'Alte taxe si Ajustari', 'Beneficii', 'Taxa Conectare ISP', 'Utilizare suplimentara ISP', 'Servicii speciale ISP', 'Echipamente si accesorii', 'Reduceri', 'delimiter', 'Total factura curenta fara TVA', 'TVA 19%', 'Echipamente si accesorii taxare inversa', 'Total sume neincluse in baza de impozitare TVA', 'Penalizari', 'delimiter', 'Total factura curenta', 'Total rate terminal', 'Total de plata'];
    const previous_bill = ['Sold precedent', 'Total platit din sold precedent', 'Corectii', 'Returnare depozit', 'Rest plata'];
    const current_bill = ['Abonamente si extraoptiuni', 'Servicii utilizate', 'Chirii echipamente', 'Alte taxe si Ajustari', 'Beneficii', 'Taxa Conectare ISP', 'Utilizare suplimentara ISP', 'Servicii speciale ISP', 'Echipamente si accesorii', 'Reduceri', 'delimiter', 'Total factura curenta fara TVA', 'TVA 19%', 'Echipamente si accesorii taxare inversa', 'Total sume neincluse in baza de impozitare TVA', 'Penalizari', 'delimiter', 'Total factura curenta', 'Total rate terminal', 'Total de plata'];
    let categoryIndex = null;
    let delimiterIndex = 0;

    const tplItemName = (item) => {
        if (item === "Alte taxe si Ajustari") {
            return item.charAt(0).toUpperCase() + item.toLowerCase().slice(1)
        } else if (item === "Echipamente si accesorii taxare inversa") {
            return "Echipamente si accesorii la care se aplica taxare inversa"
        } else if (item === "Taxa Conectare ISP") {
            return "Taxa conectare ISP"
        } else {
            return item
        }
    }

    return html`
    <div class="chat-msg__body-OOBChargesDetailed">
        <h4 class="chat-msg__body-OOBChargesDetailed--header">Factura emisa in data de <b>${oob_charges_detailed.bill_date}</b> cu seria si numarul <b>${oob_charges_detailed.bill_no}</b>:</h4>
        <hr class="OOBChargesDetailed-line-delimiter">
        <div class="chat-msg__body-OOBChargesDetailed--list">
            ${ oob_charges_order.map((item, index) => {
                if ((item === "Total sume neincluse in baza de impozitare TVA" || item === "Penalizari") && oob_charges_detailed?.charges?.[item]) {
                    if(item === "Total sume neincluse in baza de impozitare TVA" && (oob_charges_detailed?.charges?.[item] !== oob_charges_detailed?.charges?.["Penalizari"] || !oob_charges_detailed?.charges?.["Penalizari"])) {
                        categoryIndex = index;
                        return html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                            <h4 class="chat-msg__body-OOBChargesDetailed--item-title">Total sume neincluse in baza de impozitare TVA</h4>
                            <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${oob_charges_detailed.charges[item].toFixed(2)} ${oob_charges_detailed.unit}</b></h4>
                        </div>` 
                    } else if (item === "Penalizari" && (oob_charges_detailed?.charges?.["Total sume neincluse in baza de impozitare TVA"] === oob_charges_detailed?.charges?.[item] || !oob_charges_detailed?.charges?.["Total sume neincluse in baza de impozitare TVA"])) {
                        categoryIndex = index;
                        return html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                            <h4 class="chat-msg__body-OOBChargesDetailed--item-title">Penalizari</h4>
                            <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${oob_charges_detailed.charges[item].toFixed(2)} ${oob_charges_detailed.unit}</b></h4>
                        </div>` 
                    } else return null;
                } else if (oob_charges_detailed?.charges?.[item]) {
                    categoryIndex = index;
                    return html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                        <h4 class="chat-msg__body-OOBChargesDetailed--item-title">${tplItemName(item)}</h4>
                        <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${oob_charges_detailed.charges[item].toFixed(2)} ${oob_charges_detailed.unit}</b></h4>
                    </div>` 
                } else if (item === "delimiter" && categoryIndex !== null && index > categoryIndex && delimiterIndex <= categoryIndex ) {
                    delimiterIndex = index;
                    return html`<hr class="OOBChargesDetailed-line-delimiter">`
                } else if (item === "Factura anterioara" && previous_bill.filter(item => oob_charges_detailed?.charges?.[item]).length > 0) {
                    return html`<h4 class="chat-msg__body-OOBChargesDetailed--header"><b>${item}</b></h4>`
                } else if (item === "Factura curenta" && current_bill.filter(item => oob_charges_detailed?.charges?.[item]).length > 0) {
                    return html`<h4 class="chat-msg__body-OOBChargesDetailed--header"><b>${item}</b></h4>`
                } else if (item === "Total factura curenta fara TVA" && oob_charges_detailed?.total_without_tva) {
                    return html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                        <h4 class="chat-msg__body-OOBChargesDetailed--item-title">Total factura curenta fara TVA</h4>
                        <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${oob_charges_detailed?.total_without_tva.toFixed(2)} ${oob_charges_detailed.unit}</b></h4>
                    </div>`
                }
                return null
            })}
        </div>
    </div>`
}

import { html } from "lit"; // custom change - new file

export default () => {
    return html`<div class="spinner-custom-container">
        <div class="spinner-custom-group">
            <img src="https://static.vodafone.ro/tobi/images/tobi-icon.webp" class="spinner-custom" alt="Tobi" />
            <p class="spinner-text spinner-text-app">Se încarcă...</p>
        </div>
    </div>`
}

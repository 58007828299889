// custom change - new file
import { html } from 'lit';

export default (thank_you_card) => {
    
    return html`
        <div class="chat-msg__body-thank-you-card">
            <div class="chat-msg__body-thank-you-card-header">
                <h3 class="thank-you-card-header-title">${thank_you_card.title}</h3>
            </div>
            <h5 class="thank-you-card-description">${thank_you_card.description}</h5>
        </div>`
}

// custom change - new file
import { html } from 'lit';

export default (el, offer, descriptions, index) => {
    return html`
    <div class="offer-container d-flex">
        <div class="offer-wrapper">
            <div class="offer-card-content">
                <div class="offer-title">
                    <div>${offer.Nume}</div>
                </div>
                <div class="offer-price-container">
                    <div class="offer-price-wrapper">
                        <span>
                            <span class="offer-price-int">${offer.Cost}</span>
                            <span class="offer-price-dec">€<span class="offer-price-month" ><b>/lună</b></span></span>
                        </span>
                    </div>
                    <div class="offer-validity">
                        Valabilitate ${offer.Valabilitate}
                    </div>
                </div>
                <span class="offer-link-wrapper">
                    <div class="offer-button-wrapper">
                        <button class="offer-button-container medium secondary chevron right chat-msg__action-activate-offer" type="button" sec_uuid="a78987319364" activate="${offer.Nume}" @click=${ev => el.onActivateOfferClicked(ev)}>
                            Activează
                            <span class="offer-button-icon svg-icon chevron-right">
                                <svg viewBox="0 0 24 24">
                                    <g stroke="none" stroke-width="2" fill="none" fillrule="evenodd">
                                        <g>
                                            <g>
                                                <polyline stroke="currentColor" strokelinecap="round" strokelinejoin="round" points="8.5 3.5 17 12 8.5 20.5"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </button>
                    </div>
                </span>
                <div class="offer-listing-wrapper">
                ${descriptions[index].map(description => {
                    return html`
                    <div class="offer-listing-item">
                        <div class="desc">
                        ${description}
                        </div>
                    </div>` 
                })}
                </div>
            </div>
        </div>
    </div>`
}

// custom change - new file
import { html } from 'lit';

export default (el, oob_extended_item, obj) => {
    const oob_charges_included_in_total = ['Abonamente_si_extraoptiuni', 'Servicii_Nationale', 'Servicii_Internationale', 'Servicii_Roaming', 'Beneficii', 'Echipamente_cu_taxare_inversa', 'Taxa_conectare', 'Alte_taxe_si_ajustari', 'Reduceri', 'Reduceri_speciale'];
    const oob_charges_excluded_from_total = ['Servicii_incasate_in_numele_tertilor', 'Taxa_reziliere', 'Ajustare_taxa_Reziliere', 'Rate_terminal'];
    
    const tplDisplayCategoryName = (category) => {
        if (category === "Servicii_Nationale") {
            return "Servicii Nationale";
        } else {
            return oob_extended_item[category][0].billSummaryItemCategory;
        }
    }

    const tplDisplayCategories = (oob_extended_item_category) => {
        if (oob_extended_item?.[oob_extended_item_category]?.length > 0) {
            return html`
                <hr class="OOBExtendedDetailed-line-delimiter">
                ${ obj.info_tooltip?.[oob_extended_item_category] && Object.keys(obj.info_tooltip?.[oob_extended_item_category]).length > 0 ? 
                    html`${el.renderInfoTooltip(obj.info_tooltip[oob_extended_item_category], `${oob_extended_item_category}_${obj.id}`)}
                        <div class="chat-msg__body-OOBExtendedDetailed--category-grid">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-question-circle-fill chat-msg__body-OOBExtendedDetailed--category-tooltip" viewBox="0 0 16 16" @click=${ev => el.onTooltipClicked(ev, `${oob_extended_item_category}_${obj.id}`)}>
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                            </svg>
                            <h3 class="chat-msg__body-OOBExtendedDetailed--header-tooltip">${tplDisplayCategoryName(oob_extended_item_category)}:</h3>
                        </div>` : 
                        html`<h3 class="chat-msg__body-OOBExtendedDetailed--header">${tplDisplayCategoryName(oob_extended_item_category)}:</h3>` }
                ${tplMapCategory(oob_extended_item_category)}`
        }
    }

    const tplMapCategory = (category) => {
        if (category === "Abonamente_si_extraoptiuni" && oob_extended_item.Plan_Change_Prorata && oob_extended_item.Prorate_Calc) {
            return html`
                ${el.renderInfoTooltip(oob_extended_item.Tooltip_Prorata, `Prorata_${obj.id}_${oob_extended_item.Numar}`)}
                <div class="chat-msg__body-OOBExtendedDetailed--prorate-grid-tooltip">
                    <div class="chat-msg__body-OOBExtendedDetailed--prorate-grid-charges">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-question-circle-fill chat-msg__body-OOBExtendedDetailed--category-tooltip" viewBox="0 0 16 16" @click=${ev => el.onTooltipClicked(ev, `Prorata_${obj.id}_${oob_extended_item.Numar}`)}>
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                        </svg>
                    </div>
                    <div class="chat-msg__body-OOBExtendedDetailed--prorate-grid-charges">
                        ${ oob_extended_item[category].filter(item => item?.productRef?.name === oob_extended_item?.Prorate_Calc?.oldPlan?.name || item?.productRef?.name === oob_extended_item?.Prorate_Calc?.currentPlan?.name).map(item => {
                        return tplDisplayCharges(item)
                        }) }
                    </div>
                </div>
                ${ oob_extended_item[category].filter(item => item?.productRef?.name !== oob_extended_item?.Prorate_Calc?.oldPlan?.name && item?.productRef?.name !== oob_extended_item?.Prorate_Calc?.currentPlan?.name).map(item => {
                    return tplDisplayCharges(item)
                }) }`
        } else {
            return html`${ oob_extended_item[category].map(item => {
                return tplDisplayCharges(item)
             }) }`
        }
    }

    const tplDisplayCharges = (item) => {
        return html`<div class="chat-msg__body-OOBExtendedDetailed--item-grid">
                        <h4 class="chat-msg__body-OOBExtendedDetailed--item-title">${item.productRef.name}</h4>
                        <h4 class="chat-msg__body-OOBExtendedDetailed--item-value">${item.totalAmount.value} ${item.totalAmount.unit}</h4>
                    </div>`
    }

    return html`
    <div class="chat-msg__body-OOBExtendedDetailed d-flex">
        <div class="chat-msg__body-OOBExtendedDetailed-wrapper">
            <h3 class="OOBExtendedDetailed-phone-number">${oob_extended_item.Numar.startsWith("0") ? oob_extended_item.Numar : "0" + oob_extended_item.Numar}</h3>
            <div class="chat-msg__body-OOBExtendedDetailed--list">
                ${ oob_charges_included_in_total.map(item => {
                if(item === "Servicii_Nationale" && oob_extended_item?.["Reincarcare_Cartela"]?.length > 0) {
                    oob_extended_item[item] = oob_extended_item?.[item].concat(oob_extended_item?.["Reincarcare_Cartela"]);
                }
                return html`${tplDisplayCategories(item)}` }) }
            </div>
            ${ (oob_extended_item.Total?.toString().length > 0 && oob_extended_item.Unit) ? html `
                <hr class="OOBExtendedDetailed-line-delimiter">
                <div class="chat-msg__body-OOBExtendedDetailed--item-grid OOBExtendedDetailed-total">
                    <h3 class="chat-msg__body-OOBExtendedDetailed-total--header">Total fara TVA</h3>
                    <h3 class="chat-msg__body-OOBExtendedDetailed-total--item-value">${Number(oob_extended_item.Total).toFixed(2)} ${oob_extended_item.Unit}</h3>
                </div>` : null
            }
            <div class="chat-msg__body-OOBExtendedDetailed--list">
                ${ oob_charges_excluded_from_total.map(item => {
                return html`${tplDisplayCategories(item)}` }) }
            </div>
        </div>
    </div>`
}

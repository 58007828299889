// custom change - new file
import { html } from 'lit';

export default (el, url, name, channel) => {
    return html`
    <div class="chat-msg__body-callout">
        ${(channel === "mva") ? html`
            <a class="chat-msg__callout-button chat-msg__button mva-navigation" link="${url}" @click=${ev => el.onMvaNavigationClicked(ev)}>${name}</a>` : html`
            <a class="chat-msg__callout-button chat-msg__button" href="${url}" title="${name}" target="_blank">${name}</a>`}
    </div>`
}

// custom change - new file
import { html } from 'lit';

export default (el, offer_info) => {
    return html`
    <div class="price-plan-container">
        <div class="price-plan-wrapper d-flex">
            <span class="scroll-arrow left-arrow arrow-invisible" @click=${ev => el.onLeftArrowClicked(ev)}>
                <span class="scroll-svg svg-icon chevron-left">
                    <svg viewBox="0 0 24 24">
                        <g stroke="none" stroke-width="2" fill="none" fillrule="evenodd">
                            <g>
                                <g>
                                    <polyline stroke="currentColor" strokelinecap="round" strokelinejoin="round" points="15.5 20.5 7 12 15.5 3.5"></polyline>
                                </g>
                            </g>
                        </g>
                    </svg>
                </span>
            </span>
            ${offer_info.offers.map((offer, index) => {
                return html`${el.renderOffersCard(offer, offer_info.descriptions, index)}` 
            })}
            <span class="scroll-arrow right-arrow arrow-invisible" @click=${ev => el.onRightArrowClicked(ev)}>
                <span class="scroll-svg svg-icon chevron-right">
                    <svg viewBox="0 0 24 24">
                        <g stroke="none" stroke-width="2" fill="none" fillrule="evenodd">
                            <g>
                                <g>
                                    <polyline stroke="currentColor" strokelinecap="round" strokelinejoin="round" points="8.5 3.5 17 12 8.5 20.5"></polyline>
                                </g>
                            </g>
                        </g>
                    </svg>
                </span>
            </span>
        </div>
    </div>
    <img class="tobi-avatar offer-avatar" src="https://static.vodafone.ro/tobi/images/tobi-icon.webp" alt="TOBi avatar">` 
}

// custom change - new file
import { html } from 'lit';

export default (el, obj, id) => {
    let channel = "web";
    if(window.ReactNativeWebView && obj?.url?.startsWith("navigation:")){
        channel = "mva";
    }
    const tplNavigation = () => {
        if (channel === "mva") {
            return html `
            <button class="tooltip-modal-button tooltip-modal-button-mva mva-navigation" link="${obj.url}" @click=${ev => el.onMvaNavigationClicked(ev)}>Detalii costuri</button>
            <p class="tooltip-mva-button-clarification">*selecteaza sectiunea Detalii costuri</p>`
        } else {
            return html `
            <a class="tooltip-modal-button-anonymous" href="${obj.url}" title="Detalii costuri" target="_blank">
                <button class="tooltip-modal-button">Detalii costuri</button>
            </a>`
        }
    }

    return html`
    <div id=${id} class="tooltip-modal">
        <div class="tooltip-modal-content-container">
            <div class="tooltip-modal-content-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-square-fill tooltip-modal-close" @click=${ev => el.onCloseTooltip(ev, id)} viewBox="0 0 16 16">
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708"/>
                </svg>
            </div>
            <div class="tooltip-modal-content-text">
            ${obj?.text?.split("<br/>").map((item) => {
                return html `<p>${item}</p>`
            })}
            ${ obj.url ? html `
                <div class="tooltip-modal-button-container">
                ${tplNavigation()}
                </div>` : ``}
            <div>
        </div>
    </div>` 
}

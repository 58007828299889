// custom change - new file
import { html } from 'lit';

export default (el, pa_info) => {
    
    return html`
    <div class="chat-msg__body-pa-info">
        <h5 class="pa-info-description">${pa_info.description}</h5>
        <input type="date" class="pa-info-input pa-info-date" name="agreement" placeholder="Alege&nbsp;data" min='${pa_info.min_date}' max='${pa_info.maximum_date}' @focus=${ev => el.onFocus(ev)} required>
        <p class="pa-info-input-feedback pa-info-input-feedback--not-displayed"></p>
        <div class="pa-answer-container">
            ${pa_info.buttons.map(button => {
                return html`<button class="pa-info-button chat-msg__action-pa-reply" @click=${ev => el.onPAReplyClicked(ev)}>${button}</button>`
            })}
        </div>
    </div>`
}

import 'shared/avatar/avatar.js';
import 'shared/chat/unfurl.js';
import { __ } from 'i18n';
import { html } from "lit";
import { shouldRenderMediaFromURL } from '@converse/headless/utils/url.js';

// custom change - added new parameter 'obj'
export default (el, o, obj) => {
    // custom change - new line 
    const custom_elements = ["billInfoCard", "PCCard", "ThankYouCard", "PACard", "OffersCarousel", "OOBChargesCard", "OOBChargesDetailedCard", "OOBExtendedCard","OOBChargesBillMgmtCard", "MyPlanCard"];
    // custom change - removed const i18n_new_messages = __('New messages');
    const is_followup = el.model.isFollowup();
    // custom change - added "chat-msg__container--${o.sender}" as a class to the first div

    // custom change - removed 
    // ${ (o.sender === "them" && !is_followup) ? html`
    //     <span class="chat-msg__heading">
    //     <span class="chat-msg__author"><a class="show-msg-author-modal" @click=${el.showUserModal}>${o.username}</a></span>
    //     ${ o.hats.map(h => html`<span class="badge badge-secondary">${h.title}</span>`) }
    //     <time timestamp="${el.model.get('edited') || el.model.get('time')}" class="chat-msg__time">${o.pretty_time}</time>
    //     ${ o.is_encrypted ? html`<converse-icon class="fa fa-lock" size="1.1em"></converse-icon>` : '' }
    // </span>` : '' }

    // custom change - added
    // <span class="chat-msg__heading">
    //     <time timestamp="${el.model.get('edited') || el.model.get('time')}" class="chat-msg__time">${o.pretty_time}</time>
    //     ${ o.is_encrypted ? html`<converse-icon class="fa fa-lock" size="1.1em"></converse-icon>` : '' }
    // </span>
    // at the end

    // custom change - added "chat-msg__body--${o.sender}"
    // custom change - removed ${ o.is_first_unread ? html`<div class="message separator"><hr class="separator"><span class="separator-text">${ i18n_new_messages }</span></div>` : '' } from line 32

    // custom change - added 
    // ${ (obj.is_custom_msg && o.sender === "them" && obj.custom_msg_type === 'quickReplies') ?
    //     html`<div class="short-answer-container">${el.renderQuickReplies(obj.quick_replies_buttons)}</div>` : ''
    // }

    // custom change - added functions
    // tplCustomMessages
    // tplMessages
    // tplOffers

    // custom change - added
    // ${ (o.sender === "them") ? html `${tplOffers(el, o, obj)}` : html `
    //                 ${tplCustomMessages(el, o, obj)}
    //                 ${tplMessages(el, o, obj)}`}

    // custom change - added
    //${obj.offer_info || obj.oob_extended_detailed_info ? 'chat-msg__content--carousel' : ''}


    const tplCustomMessages = (el, o, obj) => {
        if(o.sender === "them") {
            if(obj.bill_info) {
                return html `${el.renderBillInfo(obj.bill_info)}`
            } else if (obj.pa_info) {
                return html `${el.renderPaCard(obj.pa_info)}`
            } else if (obj.pc_info) {
                return html `${el.renderPcCard(obj.pc_info)}`
            } else if (obj.thank_you_card) {
                return html `${el.renderThankYouCard(obj.thank_you_card)}`
            } else if (obj.offer_info) {
                return html `${el.renderOffersCarousel(obj.offer_info)}`
            } else if (obj.oob_charges_info) {
                return html `${el.renderOOBCharges(obj.oob_charges_info)}`
            } else if (obj.oob_charges_detailed_info) {
                return html `${el.renderOOBChargesDetailed(obj.oob_charges_detailed_info)}`
            } else if (obj.oob_extended_detailed_info) {
                return html `${el.renderOOBExtendedCarousel(obj.oob_extended_detailed_info)}`
            } else if (obj.oob_charges_bill_mgmt_info) {
                return html `${el.renderOOBChargesBillMgmt(obj.oob_charges_bill_mgmt_info)}`
            } else if (obj.my_plan_info) {
                return html `${el.renderMyPlanCard(obj.my_plan_info)}`
            }
        }
    }

    const tplMessages = (el, o, obj, text_message) => {
        if(obj.text_message && obj.text_message.length > 0) {
            if(obj.offer_info || obj.oob_extended_detailed_info) {
                return html `
                <div class="avatar-layout">
                    <img class="tobi-avatar" src="https://static.vodafone.ro/tobi/images/tobi-icon.webp" alt="TOBi avatar">
                    <div class="messages-layout">
                        <div class="chat-msg__body chat-msg__body--${o.sender} chat-msg__body--${o.message_type} ${o.received ? 'chat-msg__body--received' : '' } ${o.is_delayed ? 'chat-msg__body--delayed' : '' }">
                                <div class="chat-msg__message">
                                    ${ (o.is_me_message) ? html`
                                        <time timestamp="${o.edited || o.time}" class="chat-msg__time">${o.pretty_time}</time>&nbsp;
                                        <span class="chat-msg__author">${ o.is_me_message ? '**' : ''}${o.username}</span>&nbsp;` : '' }
                                    ${ o.is_retracted ? el.renderRetraction() : el.renderMessageText(obj, text_message) }
                                </div>
                                <converse-message-actions
                                    .model=${el.model}
                                    ?is_retracted=${o.is_retracted}></converse-message-actions>
                        </div>
                    </div>
                </div>`
            } else {
                return html `
                <div class="chat-msg__body chat-msg__body--${o.sender} chat-msg__body--${o.message_type} ${o.received ? 'chat-msg__body--received' : '' } ${o.is_delayed ? 'chat-msg__body--delayed' : '' }">
                        <div class="chat-msg__message">
                            ${ (o.is_me_message) ? html`
                                <time timestamp="${o.edited || o.time}" class="chat-msg__time">${o.pretty_time}</time>&nbsp;
                                <span class="chat-msg__author">${ o.is_me_message ? '**' : ''}${o.username}</span>&nbsp;` : '' }
                            ${ o.is_retracted ? el.renderRetraction() : el.renderMessageText(obj, text_message) }
                        </div>
                        <converse-message-actions
                            .model=${el.model}
                            ?is_retracted=${o.is_retracted}></converse-message-actions>
                    </div>`
            }
        }
    }

    const tplOffers = (el, o, obj) => {
        if(obj.offer_info || obj.oob_extended_detailed_info) {
            return html `
                ${ obj.text_message.map(m => {
                        if (custom_elements.includes(m)) {
                            return html `${tplCustomMessages(el, o, obj)}`
                        } else {
                            return html `${tplMessages(el, o, obj, m)}`
                        } 
                    }) 
                }`
        } else {
            return html `
            <div class="avatar-layout">
                <img class="tobi-avatar" src="https://static.vodafone.ro/tobi/images/tobi-icon.webp" alt="TOBi avatar">
                <div class="messages-layout">
                ${ obj.text_message.map(m => {
                        if (custom_elements.includes(m)) {
                            return html `${tplCustomMessages(el, o, obj)}`
                        } else {
                            return html `${tplMessages(el, o, obj, m)}`
                        } 
                    }) 
                }
                </div>
            </div>`
        }
    }

    return html`
        <div class="message chat-msg chat-msg__container--${o.sender} ${ el.getExtraMessageClasses() }"
                data-isodate="${o.time}"
                data-msgid="${o.msgid}"
                data-from="${o.from}"
                data-encrypted="${o.is_encrypted}">

            <!-- Anchor to allow us to scroll the message into view -->
            <a id="${o.msgid}"></a>

            ${ (o.should_show_avatar && !is_followup) ?
                html`<a class="show-msg-author-modal" @click=${el.showUserModal}>
                    <converse-avatar
                        class="avatar align-self-center"
                        .data=${el.model.vcard?.attributes}
                        nonce=${el.model.vcard?.get('vcard_updated')}
                        height="40" width="40"></converse-avatar>
                </a>` : '' }

            <div class="chat-msg__content chat-msg__content--${o.sender} ${o.is_me_message ? 'chat-msg__content--action' : ''} ${obj.offer_info || obj.oob_extended_detailed_info ? 'chat-msg__content--carousel' : ''}">
                ${ (o.sender === "them") ? html `${tplOffers(el, o, obj)}` : html `
                    ${tplCustomMessages(el, o, obj)}
                    ${tplMessages(el, o, obj)}`}

                ${ el.model.get('ogp_metadata')?.map(m => {
                    if (el.model.get('hide_url_previews') === true) {
                        return '';
                    }
                    if (!shouldRenderMediaFromURL(m['og:image'], 'image')) {
                        return '';
                    }
                    return html`<converse-message-unfurl
                        @animationend="${el.onUnfurlAnimationEnd}"
                        class="${el.model.get('url_preview_transition')}"
                        jid="${el.chatbox?.get('jid')}"
                        description="${m['og:description'] || ''}"
                        title="${m['og:title'] || ''}"
                        image="${m['og:image'] || ''}"
                        url="${m['og:url'] || ''}"></converse-message-unfurl>` }) }
                <span class="chat-msg__heading">
                    <time timestamp="${el.model.get('edited') || el.model.get('time')}" class="chat-msg__time">${o.pretty_time}</time>
                    ${ o.is_encrypted ? html`<converse-icon class="fa fa-lock" size="1.1em"></converse-icon>` : '' }
                </span>
                ${ (obj.is_custom_msg && o.sender === "them" && obj.custom_msg_type === 'quickReplies') ?
                    html`<div class="short-answer-container">${el.renderQuickReplies(obj.quick_replies_buttons)}</div>` : ''
                }
            </div>
        </div>`;
}

// custom change - new file
import { html } from 'lit';

export default (el, arr) => {
    
    return html`
        ${arr.map(quick_reply => {
            return html`<button class="chat-msg__action chat-msg__action-quick-reply short-answer-button" @click=${ev => el.onQuickReplyClicked(ev)}>${quick_reply}</button>` 
        })}`
}

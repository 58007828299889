// custom change - new file
import { html } from 'lit';

export default (el, oob_charges_detailed) => {
    const oob_charges_order = ['Abonamente_si_extraoptiuni', 'Servicii_Nationale', 'Servicii_Internationale', 'Servicii_Roaming', 'Beneficii', 'Sume_incasate_in_numele_tertilor', 'Echipamente_cu_taxare_inversa', 'Taxa_conectare', 'Taxa_reziliere', 'Alte_taxe_si_ajustari', 'Ajustare_taxa_Reziliere', 'Reduceri', 'Reduceri_speciale'];
    return html`
    <div class="chat-msg__body-OOBChargesDetailed">
        <h4 class="chat-msg__body-OOBChargesDetailed--header">Factura emisa in data de <b>${oob_charges_detailed.bill_date}</b> cu seria si numarul <b>${oob_charges_detailed.bill_no}</b>:</h4>
        <div class="chat-msg__body-OOBChargesDetailed--list">
            ${ (oob_charges_detailed.bill_balance !== 0) ? 
            html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                    <h4 class="chat-msg__body-OOBChargesDetailed--item-title">Rest plata factura anterioara</h4>
                    <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${(oob_charges_detailed.bill_balance - oob_charges_detailed.total).toFixed(2)} ${oob_charges_detailed.unit}</b></h4>
                </div>
                <hr class="OOBChargesDetailed-line-delimiter">` : '' }
            ${ oob_charges_order.map(item => {
                    if (oob_charges_detailed?.charges?.[item]) {
                        return html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                        <h4 class="chat-msg__body-OOBChargesDetailed--item-title">${item.replaceAll("_", " ")}</h4>
                        <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${oob_charges_detailed.charges[item]} ${oob_charges_detailed.unit}</b></h4>
                    </div>` }
                    return null
                })
            }
            ${ oob_charges_detailed.other_charges ? 
            html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                    <h4 class="chat-msg__body-OOBChargesDetailed--item-title">Alte costuri</h4>
                    <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${oob_charges_detailed.other_charges} ${oob_charges_detailed.unit}</b></h4>
                </div>` : '' }
            <hr class="OOBChargesDetailed-line-delimiter">
            ${ oob_charges_detailed.charges.Rate_terminal ? 
                html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                    <h4 class="chat-msg__body-OOBChargesDetailed--item-title">Rate terminal</h4>
                    <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${oob_charges_detailed.charges.Rate_terminal} ${oob_charges_detailed.unit}</b></h4>
                </div>` : '' }
            ${ oob_charges_detailed.TVA ? 
            html`<div class="chat-msg__body-OOBChargesDetailed--item-grid">
                    <h4 class="chat-msg__body-OOBChargesDetailed--item-title">TVA:</h4>
                    <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${Number(oob_charges_detailed.TVA).toFixed(2)} ${oob_charges_detailed.unit}</b></h4>
                </div>` : '' }
            <div class="chat-msg__body-OOBChargesDetailed--item-grid">
                <h4 class="chat-msg__body-OOBChargesDetailed--item-title">Total de plata:</h4>
                <h4 class="chat-msg__body-OOBChargesDetailed--item-value"><b>${oob_charges_detailed.bill_balance.toFixed(2)} ${oob_charges_detailed.unit}</b></h4>
             </div>
        </div>
    </div>`
}

// custom change - new file
import { html } from 'lit';

export default (el, pc_info) => {
    return html`
        <div class="chat-msg__body-pc-info">
            <h3 class="pc-info-title">Total de plată ${pc_info.totalAmount} lei</h3>
            <h5 class="pc-info-description">${pc_info.description}</h5>
            ${(pc_info.input ) ? html`
                <input type="text" class="pc-info-input pc-info-partial-sum" name="sum" placeholder="${pc_info.input}" @focus=${ev => el.onFocus(ev)} required>
                <p class="pc-info-input-feedback pc-info-input-feedback--not-displayed">Va rog sa introduceti doar cifre!</p>` : ''
            }
            <div class="pc-answer-container">
            ${pc_info.buttons.map(button => {
                return html`<button class="pc-info-button chat-msg__action-pc-reply" @click=${ev => el.onPCReplyClicked(ev)}>${button}</button>`
            })}
            </div>
        </div>`
}

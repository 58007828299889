// custom change - new file
import { html } from 'lit';

export default (el, bill_info) => {

    const tplDetailsLink = (bill_info) => {
        if (bill_info.channel === "mva") {
            return html `
            <div class="billInfo-header-navigation mva-navigation" link="${bill_info.details_link}" @click=${ev => el.onMvaNavigationClicked(ev)}>
                <h5 class="billInfo-header-navigation-title" link="${bill_info.details_link}">Detalii</h5>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right billInfo-header-navigation-link" link="${bill_info.details_link}" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>`
        } else {
            return html `
            <a class="billInfo-header-navigation-anonymous" href="${bill_info.details_link}" title="Detalii factură" target="_blank">
                <div class="billInfo-header-navigation">
                    <h5 class="billInfo-header-navigation-title">Detalii</h5>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right billInfo-header-navigation-link" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
            </a>`
        }
    }

    const tplPayment = (bill_info) => {
        if (bill_info.channel === "mva") {
            return html `
            <button class="billInfo-payment-button billInfo-payment-button-mva mva-navigation" link="${bill_info.pay_link}" @click=${ev => el.onMvaNavigationClicked(ev)}>Plătește</button>`
        } else {
            return html `
            <a class="billInfo-payment-button-anonymous" href="${bill_info.pay_link}" title="Plată factură" target="_blank">
                <button class="billInfo-payment-button">Plătește</button>
            </a>`
        }
    }
    
    return html`
    <div class="chat-msg__body-billInfo">
        <div class="chat-msg__body-billInfo--header">
            <h5 class="billInfo-header-title">${bill_info.bill_date_interval}</h5>
            <div class="chat-msg__body-billInfo--header-grid">
                ${ (bill_info.details_link) ? html `
                    <div class="chat-msg__body-billInfo--header-details">
                        <h3 class="billInfo-header-month">${bill_info.valid_month}</h3>
                    </div>
                    ${tplDetailsLink(bill_info)}` : html `
                    <div class="chat-msg__body-billInfo--header-details header-margin">
                        <h3 class="billInfo-header-month">${bill_info.valid_month}</h3>
                    </div>`}
            </div>
        </div>
        <div class="chat-msg__body-billInfo--payment">
            ${ (bill_info.phone_number) ? html `<h3 class="billInfo-payment-title">Total de plată pentru</h3>
            <h5 class="billInfo-payment-phone-number">${bill_info.phone_number}</h5>` : html `<h3 class="billInfo-payment-title">Total de plată</h3>`}
            ${ (!bill_info.paid) ? html `<h5 class="billInfo-payment-subtitle">până la ${bill_info.due_day} ${bill_info.due_month}</h5> ` : ''}
            <h2 class="billInfo-payment-amount">${bill_info.amount_value} ${bill_info.amount_unit}</h2>
            ${ (!bill_info.paid) ? html `
                ${tplPayment(bill_info)}` : html `<button class="billInfo-payment-button" disabled>Factura este plătită</button>`}
        </div>
    </div>`
}

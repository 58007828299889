import { __ } from 'i18n';
import { html } from 'lit';
import { until } from 'lit/directives/until.js';

function tplSendButton () {
    // custom change - added xml and svg tags
    // was "<converse-icon color="var(--toolbar-btn-text-color)" class="fa fa-paper-plane" size="1em"></converse-icon>"
    // custom change - added
    // <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-double-down scroll-bottom-btn hide-btn" viewBox="0 0 16 16" @click=${ev => el.onScrollBtnClicked(ev)} @touchend=${ev => el.onScrollBtnClicked(ev)}>
    //     <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" id="mainIconPathAttribute"></path> 
    //     <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" id="mainIconPathAttribute"></path> </svg>
    // </svg>
    const i18n_send_message = __('Send the message');
    return html`<button type="submit" class="btn send-button" title="${ i18n_send_message }">
        <?xml version="1.0" encoding="UTF-8"?>
        <svg width="15px" height="16px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
            <g id="VFRO---TOBi" stroke="none" stroke-width="2" fill="none" fill-rule="evenodd">
                <g id="01.-Tobi-Tray-Copy-3" transform="translate(-315.000000, -575.000000)">
                    <g id="Input-/-Chat-/-Mobile-/-Inactive-/-Light" transform="translate(16.000000, 561.000000)">
                        <g id="Input+Controls-/-Chat-/-Inactive">
                            <g id="Icon" transform="translate(292.000000, 8.000000)">
                                <g id="Icons+Indicators-/-System-Icons-/-arrow_up-/-#FFFFFF-" transform="translate(5.000000, 5.000000)">
                                    <g id="arrow-up">
                                        <polyline id="Path" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" points="15.375 8.25 9 1.875 2.625 8.25"></polyline>
                                        <line x1="9.003125" y1="1.875" x2="9.003125" y2="16.125" id="fb6a91cd-0bad-4e11-9667-05009fa783e6" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></line>
                                        <rect id="Rectangle" x="0" y="0" width="18" height="18"></rect>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </button>`
}

export default (el) => {
    return html`
        <span class="toolbar-buttons">${until(el.getButtons(), '')}</span>
        ${ el.show_send_button ? tplSendButton() : '' }
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-double-down scroll-bottom-btn hide-btn" viewBox="0 0 16 16" @click=${ev => el.onScrollBtnClicked(ev)} @touchend=${ev => el.onScrollBtnClicked(ev)}>
            <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" id="mainIconPathAttribute"></path> 
            <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" id="mainIconPathAttribute"></path> </svg>
        </svg>
    `;
}

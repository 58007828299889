// custom change - new file
import { html } from 'lit';

export default (el, oob_charges) => {
    const tplPayment = () => {
        if (oob_charges.channel === "mva") {
            return html `
            <button class="OOBCharges-payment-button OOBCharges-payment-button-mva mva-navigation" link="${oob_charges.pay_link}" @click=${ev => el.onMvaNavigationClicked(ev)}>Plateste factura</button>`
        } else {
            return html `
            <a class="OOBCharges-payment-button-anonymous" href="${oob_charges.pay_link}" title="Plata factura" target="_blank">
                <button class="OOBCharges-payment-button">Plateste factura</button>
            </a>`
        }
    }

    const tplDownload = () => {
        if (oob_charges.channel === "mva") {
            return html `
            <button class="OOBCharges-download-button OOBCharges-download-button-mva mva-navigation" link="${oob_charges.download_bill_link}" @click=${ev => el.onMvaNavigationClicked(ev)}>Descarca factura PDF</button>`
        } else {
            return html `
            <a class="OOBCharges-download-button-anonymous" href="${oob_charges.download_bill_link}" title="Descarca factura PDF" target="_blank">
                <button class="OOBCharges-download-button">Descarca factura PDF</button>
            </a>`
        }
    }
    
    return html`
    <div class="chat-msg__body-OOBCharges">
        <div class="chat-msg__body-OOBCharges--header">
            <div class="chat-msg__body-OOBCharges--header-grid">
                 <div class="chat-msg__body-OOBCharges--header-details">
                    <h3 class="OOBCharges-header-month">Ultima factura: ${oob_charges.valid_month}</h3>
                    <h5 class="OOBCharges-header-title">${oob_charges.bill_date_interval}</h5>
                </div>
                <div class="OOBCharges-header-${oob_charges.paid ? "paid" : "unpaid"}">${oob_charges.paid ? "Platita" : "Neplatita"}</div>
            </div>
            
        </div>
        <div class="chat-msg__body-OOBCharges--payment">
            <div class="chat-msg__body-OOBCharges--payment-grid">
                <h3 class="OOBCharges-payment-title">Total factura</h3>
                <div class="OOBCharges-payment-amount">
                    <h3 class="OOBCharges-payment-total">${oob_charges.amount_value.toFixed(2)} ${oob_charges.amount_unit}</h3>
                </div>
            </div>
            <h5 class="OOBCharges-payment-extra">din care cost suplimentar ${oob_charges.extra_charges.toFixed(2)} ${oob_charges.amount_unit}</h5>
            ${oob_charges?.receivable_balance > 0 ? html `
                <div class="chat-msg__body-OOBCharges--payment-grid">
                    <h3 class="OOBCharges-payment-title">Total de plata</h3>
                    <div class="OOBCharges-payment-amount">
                        <h3 class="OOBCharges-payment-total">${oob_charges.receivable_balance.toFixed(2)} ${oob_charges.amount_unit}</h3>
                    </div>
                </div>
            ` : null}
            ${ (!oob_charges.paid && oob_charges.pay_link) ? html `
                ${tplPayment()}` : null}
            ${ (oob_charges.download_bill_link) ? html `
                ${tplDownload()}` : null}
        </div>
    </div>`
}
